import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import team8 from '../../assets/images/team/team8.png'

const TeamMember = () => {
    return (
        <section className="scientist-area pt-100 pb-70 bg-f1fbf4">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="team" /> 
                        The Company
                    </span>
                    <h2>Smarter Spreadsheets LLC</h2>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-scientist-item-box">
                            <div className="image">
                                <img src={team8} alt="team" />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-sm-6">
                        <h2>About Mark Frye</h2>
                        <p>Mark Frye worked in Northbrook School District for 14 years as a 7th grade science teacher.  In addition to the daily expectations for a middle school teacher, he began developing tools using spreadsheets to make his life easier.  After partnering with other teachers on projects, he was inspired to share his experiences with others and formed Smarter Spreadsheets LLC with the goal of making the difficult job of teaching easier for many more people.</p>
                        <p>Smarter Spreadsheets LLC is based in Highwood, IL</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamMember;