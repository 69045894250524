import React from 'react'
import {Link, useStaticQuery, graphql} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'

//import OwlCarousel from 'react-owl-carousel'
//import styles from 'owl.carousel/dist/assets/owl.carousel.css';
//import styles_two from 'owl.carousel/dist/assets/owl.theme.default.css';
const OwlCarousel = Loadable(() => import('react-owl-carousel'))

//console.log({styles, styles_two});

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);
    const data = useStaticQuery(graphql`
        query FeaturedTestimonialsQuery {
            allTestimonialsJson(filter: {featured: {eq: true}}) {
                nodes {
                    id
                    content
                    content_short
                    person
                    profession
                    featured
                }
            }
        }
    `);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1fbf4">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        Testimonials
                    </span>
                    <h2>What Our Clients Are Saying</h2>
                    <p>Hear from educators and school personnel how working with Smarter Spreadsheets’s founder Mark Frye has helped others.</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    {data.allTestimonialsJson.nodes.map(testimonial => {
                        return (
                        <div key={testimonial.id} className="single-testimonials-item">
                            <p>{testimonial.content_short}</p>

                            <div className="client-info">
                                <div className="d-flex justify-content-center align-items-center">
                                    <div className="title">
                                        <h3>{testimonial.person}</h3>
                                        <span>{testimonial.profession}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        );
                    })}

                </OwlCarousel> : ''}

                <div className="testimonials-view-btn text-center">
                    <Link to="/testimonials" className="default-btn">
                        <i className="flaticon-view"></i> 
                        Check Out All Reviews <span></span>
                    </Link>
                </div>
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;