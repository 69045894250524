import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import workIcon from '../../assets/images/icons/suitcase.png'
import teacherIcon from '../../assets/images/icons/education.png'
import meetingIcon from '../../assets/images/icons/meeting.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Save time. Lower stress. Spreadsheets are your answer.</h2>
                    <p>Your time is precious. We partner with school personnel to streamline current practices with data management, develop new systems to expedite data analysis and management, and create new opportunities for engagement and meaningful experiences for students, staff, and parents. Contact us today for custom spreadsheet solutions and professional development to increase comfort levels with spreadsheet management and tools.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-12 col-md-4">
                        <div className="single-solutions-box d-flex flex-column">
                            <div className="solutions-header">
                                <div className="icon">
                                    <img src={workIcon} alt="work" />
                                </div>
                                <h3>
                                    <Link to="/service-custom-spreadsheets/#administrative-team-projects">
                                        Working with <br /> Administrative Teams
                                    </Link>
                                </h3>
                            </div>
                            <ul className="features-list flex-fill">
                                <li><i class="bx bx-badge-check"></i>Attendance and “out of classroom” tracker</li>
                                <li ><i class="bx bx-badge-check"></i>Customized and quick contacts for students' teachers/parents</li>
                                <li><i class="bx bx-badge-check"></i>And much more…</li>
                            </ul>

                            <Link to="/service-custom-spreadsheets/#administrative-team-projects" className="default-btn solutions-button align-self-center">
                                <i className="flaticon-right"></i>
                                View Details
                                <span></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 col-md-4">
                        <div className="single-solutions-box d-flex flex-column">
                            <div className="solutions-header">
                                <div className="icon">
                                    <img src={teacherIcon} alt="teacher" />
                                </div>

                                <h3>
                                    <Link to="service-custom-spreadsheets/#teacher-projects">
                                        Working with <br /> Teachers
                                    </Link>
                                </h3>
                            </div>
                            <ul className="features-list flex-fill">
                                <li><i class="bx bx-badge-check"></i>Sort students into groups based on survey results</li>
                                <li><i class="bx bx-badge-check"></i>Organize assessment data to find trends/areas of improvement</li>
                                <li><i class="bx bx-badge-check"></i>And much more…</li>
                            </ul>
                            
                            <Link to="service-custom-spreadsheets/#teacher-projects" className="default-btn solutions-button align-self-center">
                                <i className="flaticon-right"></i>
                                View Details
                                <span></span>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12  col-md-4 offset-lg-0">
                        <div className="single-solutions-box d-flex flex-column">
                            <div className="solutions-header">
                                <div className="icon">
                                    <img src={meetingIcon} alt="meeting" />
                                </div>

                                <h3>
                                    <Link to="service-custom-spreadsheets/#support-personnel-projects">
                                        Working with <br /> Support Personnel
                                    </Link>
                                </h3> 
                            </div>
                            <ul className="features-list flex-fill">
                                <li><i class="bx bx-badge-check"></i>Collect data for state reporting quicker and easier</li>
                                <li><i class="bx bx-badge-check"></i>Mail/document merges for personalized reports</li>
                                <li><i class="bx bx-badge-check"></i>And much more…</li>
                            </ul>
                            
                            <Link to="service-custom-spreadsheets/#support-personnel-projects" className="default-btn solutions-button align-self-center">
                                <i className="flaticon-right"></i>
                                View Details
                                <span></span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;