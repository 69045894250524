import { Link } from 'gatsby'
import React from 'react'
//import service1 from '../../assets/images/services/service1.png'
import service1 from '../../assets/images/education/Education4.png'
import service2 from '../../assets/images/education/Education2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Custom Spreadsheets</h2>
                                    <p>Do you have data you use on a daily basis?  Do you want to use that data more effectively?  Schedule a meeting with Smarter Spreadsheets to start looking at how you can save time manipulating, sharing, and analyzing that data.</p>
                                    <Link className='default-btn' to='/service-custom-spreadsheets'>
                                        <i class="flaticon-right"></i>Look at custom projects Smarter Spreadsheets can make for you. 
                                        <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Professional Development</h2>
                                    <p>Smarter Spreadsheets LLC founder and former educator, Mark Frye, will deliver customized, differentiated professional development for your entire staff or smaller groups of school personnel to increase awareness of the tools found in Google Sheets that can make their work easier and accomplish tasks they didn't know were possible.</p>

                                    <Link className='default-btn' to='/service-professional-development'>
                                        <i class="flaticon-right"></i>Look at the type of professional development that meets your needs. 
                                        <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}

        </React.Fragment>
    )
}

export default OurServices;