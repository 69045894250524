import React from 'react'
import icon1 from '../../assets/images/funfacts/algebra.png'
import icon2 from '../../assets/images/funfacts/thumbs-up.png'
import icon3 from '../../assets/images/funfacts/clock.png'
import icon4 from '../../assets/images/funfacts/users.png'

const FunFacts = () => {
    return (
        <section className="funfacts-area bg-image pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon1} alt="features" />
                            </div>
                            <h3>5 Years</h3>
                            <p>Helping Educators</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon4} alt="features" />
                            </div>
                            <h3>25+</h3>
                            <p>Happy Clients</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon3} alt="features" />
                            </div>
                            <h3>500+</h3>
                            <p>Hours Saved</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-3 col-6">
                        <div className="single-funfacts-item">
                            <div className="icon">
                                <img src={icon2} alt="features" />
                            </div>
                            <h3>100%</h3>
                            <p>Satisfaction Rate</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunFacts